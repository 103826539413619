import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/barngrindonline/gatsby/node_modules/gatsby-theme-base/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../components/image.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "barngrind-online"
    }}>{`Barngrind online`}</h1>
    <p>{`Som förälder ligger ofta säkerhet för sitt barn högst upp på priolistan. Något man snabbt ställs inför är frågan kring barngrindar och hur man hindrar barn från att oönskat ta sig upp eller ner för trappan. Redan så tidigt som vid 5 månaders ålder kan vissa barn krypandes ta sig fram, och då är det hög tid att läsa igenom denna artikel för att veta hur du skyddar ditt barn från en eventuell trappa hemma.`}</p>
    <p>{`Med en bra grind i ditt hem, oavsett om det är till trappan, altandörren eller köket, så kan du slappna av lite mer och veta att ditt barn är tryggare än innan. Börja gärna titta runt på olika barngrindar redan innan ditt barn kan krypa, på så sätt behöver du inte få bråttom när det väl är dags.`}</p>
    <h2 {...{
      "id": "varför-behöver-du-en-barngrind"
    }}>{`Varför behöver du en barngrind?`}</h2>
    <p>{`Som förälder är du helt enkelt oberäknelig när det kommer till barnets säkerhet. Oavsett hur mycket du försöker hålla ett öga på ditt barn, kan en oövervakad sekund leda till farliga situationer, särskilt när det kommer till deras utforskande och nyfikenhet i hemmet. Att ha en barngrind är ett effektivt sätt att förhindra olyckor och skydda ditt barn från faror, i synnerhet vid trappor, dörröppningar och andra potentiellt farliga platser.`}</p>
    <h2 {...{
      "id": "tänk-på-detta-när-du-köper-en-barngrind"
    }}>{`Tänk på detta när du köper en barngrind`}</h2>
    <p>{`När det kommer till att välja rätt barngrind är det viktigt att ta hänsyn till ditt hem och dess specifika behov. Det finns olika typer av barngrindar att välja mellan, inklusive spänngrindar, fällgrindar, rullbara grindar och vikbara grindar. Varje typ har sina för- och nackdelar, och vi hjälper dig att navigera genom dessa alternativ och välja den som passar bäst för ditt hem. Vi tar även hänsyn till säkerhetsstandarder och funktioner som gör grinden användarvänlig men samtidigt säker för ditt barn.`}</p>
    <p>{`Tänk igenom följande innan du väljer vilken grind du köper:`}</p>
    <ul>
      <li parentName="ul">
        <b>Den ska vara anpassad till syftet</b>. Alla typer passar t.ex. inte högst
upp i en trappa.{" "}
      </li>
      <li parentName="ul">
        <b>Rätt bredd</b>. Köp inte en för smal grind, då går den t.ex. inte att fästa
i dörröppningen
      </li>
      <li parentName="ul">
        <b>Se till att de nuppfyller säkerhetskraven.</b> Enligt boverket finns vissa regler
som bör uppfyllas för att en grind ska anses vara säker, se nedan.
      </li>
      <li parentName="ul">
        <b>Den bör vara smidig att använda</b>. Är grinder för svår eller för krånglig
att öppna och stänga kan detta leda till att man inte använder den, och då
försvinner syftet.{" "}
      </li>
    </ul>
    <h2 {...{
      "id": "säkerhetsregler-enligt-boverket"
    }}>{`Säkerhetsregler enligt Boverket.`}</h2>
    <a href="https://www.boverket.se/">Boverket</a> är myndigheten för samhällsplanering,
byggnad och boende. Och de är de som bland annat sätter upp regler och krav som ska
uppfullas när man bygger hus. Dessa kraven är till för att byggnaden ska vara säker,
till exempel ur ett barnperspektiv. Grindar nämns inte specifikt, men det finns regler
kring hur stora vertikala mellanrum man får ha för att barn inte ska fastna eller
trilla igenom. Detta avståndet är 100 mm, eller 10 cm, och mellanrummet mellan "pinnarna"
på t.ex. en fällgrind bör inte överstiga detta. Du kan läsa fler regler på <a href="https://www.boverket.se/contentassets/2b709d86893740bab472714cb1ffb4c0/boverkets-byggregler-avsnitt-8-bfs-2011-6-tom-2014-3.pdf">
  Boverkets byggregler, BBR
    </a>
.
    <p>{`Vi kommer nedan sammanfatta de vanligaste typerna av barngrindar. Hoppa gärna sedan vidare till våra andra sidor för mer detaljerad information samt mängder med produkter du kan köpa. På dessa sidor går vi igenom hur de används, hur de monteras samt deras för- och nackdelar. Vart du ska placera en barngrind tillsammans med hur länge den ska användas kan påverka ditt val av grind.`}</p>
    <h2 {...{
      "id": "link-tospanngrindarspänngrindarlink"
    }}><Link to="/spanngrindar/" mdxType="Link">{`Spänngrindar`}</Link></h2>
    <ul>
      <li parentName="ul">{`Enkla att montera och fästa utan att behöva skruva eller borra`}</li>
      <li parentName="ul">{`Använder ett trycksystem för att hålla sig på plats mellan väggar eller dörröppningar`}</li>
    </ul>
    <p>{`Spänngrindar är den vanligaste typen av barngrindar. De installeras genom att man spänner ut den åt sidorna så att den hålls på plats med hjälp av spännkraft. En stor fördel med detta är att man varjen behöver borra eller skruva i väggen. De lämpar sig därför också perfekt om du t.ex. tillfälligt vill spärra av en dörröppning till köket, eller altandörren när du vädrar på sommaren. Man ska däremot vara medveten om att dessa ej bör placeras överst i en trappa då det finns snubbelrisk.`}</p>
    <h2 {...{
      "id": "link-tofallgrindarfällgrindarlink"
    }}><Link to="/fallgrindar/" mdxType="Link">{`Fällgrindar`}</Link></h2>
    <ul>
      <li parentName="ul">{`Mer permanenta och säkra alternativ`}</li>
      <li parentName="ul">{`Monteras med hjälp av skruvar och är idealiska för trappor och platser där extra stabilitet är viktigt`}</li>
    </ul>
    <p>{`Klasiska barngrindar som ofta är i trä. Detta anses vara den säkraste typen av barngrind, som lämpar sig väldigt bra i trappopr. Anledningen till att de är säkra är att de skruvas fast i sidorna och därmed väldigt stabilt. Utöver det är de ofta i trä eller hårdplast och ett barn kan därför inte klämma sig emellan vid sidorna eller under. Finns i varierande storlek och går ofta att måla i valfri färg för att passa in i hemmet.`}</p>
    <h2 {...{
      "id": "link-torullbararullbara-grindarlink"
    }}><Link to="/rullbara/" mdxType="Link">{`Rullbara grindar`}</Link></h2>
    <ul>
      <li parentName="ul">{`Flexibel typ av barngrind som kan användas för trappor och oregelbundna öppningar`}</li>
      <li parentName="ul">{`Justerbara och kan vikas ihop när de inte används`}</li>
    </ul>
    <p>{`Rullbara grindar är en relativt ny typ av barngrind. Själva "grinden" består i regel av tyg, ofta i något mesh-liknande material. När grinder är uppen rullas den smidigt ihop i ena änden och tar i princip ingen plats alls. Precis som med fällgrindar behöver de skruvas fast i sidorna. Här ska du också ha i åtanke att dessa ej ska placeras överst i en trappa, där ett barn kan trycka sig under det mjuka tyget.`}</p>
    <h2 {...{
      "id": "link-tovikbaravikbara-grindarlink"
    }}><Link to="/vikbara/" mdxType="Link">{`Vikbara grindar`}</Link></h2>
    <ul>
      <li parentName="ul">{`Flexibel typ av barngrind som kan användas för trappor och oregelbundna öppningar`}</li>
      <li parentName="ul">{`Justerbara och kan vikas ihop när de inte används`}</li>
    </ul>
    <p>{`Har många likheter med rullbara grindar, dock består de av mer fasta sektioner som viks ihop, istället för tyg som rullas. De blir inte riktigt lika diskreta och smidiga när de är öppna, men är endå relativt små. Dock är de i regel ganska enkla att öppna med en hand, något alla som har varit föräldrar vet är användbart så det inte är helt ovanligt att man samtidigt bär både en bebis, vällingflaska och lite kläder som ska till tvätten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      